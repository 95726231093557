<template>
    <div class="bg-color rounded-5 p-5">
        <div class="w-100 rounded-4">
            <div
                class="d-flex justify-content-between"
                style="background: rgb(241, 247, 255); border-radius: 27px 27px 0 0"
            >
                <h1 class="px-5 py-3 page-heading">RSS Build History</h1>
                <button class="text-button no-bg weight-600" style="margin-right: 10px; background: transparent">
                    <router-link class="text-link margin-0" :to="{ name: 'RSSBuilder' }">
                        <img src="@/assets/px/add.png" alt="" style="height: 20px" /> Create new RSS
                    </router-link>
                </button>
            </div>
            <div v-if="rss_list && rss_list.length > 0" class="rss-list-table profile-listing-body pb-2">
                <b-table
                    id="my-table"
                    :items="rss_list.slice().reverse()"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    bordered
                    responsive
                >
                    <template #cell(s_no)="row">
                        {{ (currentPage - 1) * perPage + row.index + 1 }}
                    </template>
                    <template #cell(company_name)>
                        {{ companyName }}
                    </template>
                    <template #cell(feedtitle)="row">
                        <b-tooltip :target="`${row.index} feedtitle`" :title="row.item.feedtitle" triggers="hover" />
                        <span :id="`${row.index} feedtitle`">{{ row.item.feedtitle }}</span>
                    </template>
                    <template #cell(created_at)="row">
                        {{ formattedDate(row.item.created_at) }}
                    </template>
                    <template #cell(modified_at)="row">
                        {{ formattedDate(row.item.updated_at) }}
                    </template>
                    <template #cell(action)="row">
                        <div class="d-flex justify-content-center align-items-center" style="gap: 20px">
                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Edit"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <router-link
                                    class="text-link margin-0"
                                    :to="{ name: 'RSSBuilder', query: { id: row.item.id } }"
                                >
                                    <img src="@/assets/px/edit-icon.png" alt="" style="height: 20px" id="target" />
                                </router-link>
                            </ejs-tooltip>
                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Delete"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <img
                                    src="@/assets/px/delete-icon.png"
                                    alt=""
                                    style="height: 20px"
                                    id="target"
                                    @click="showDeleteAlertPopUp(row.item.id)"
                                />
                            </ejs-tooltip>
                        </div>
                    </template>
                </b-table>
                <div class="b-table-pagination">
                    <div class="pagination-count-per-page">
                        <div class="pagination-number-per-page">
                            <div>
                                <span>Per page:</span>
                            </div>
                            <div>
                                <select style="" @change="changeCountPerPage" :value="perPage">
                                    <option v-for="item in perPageOptions" :key="item" :value="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <b-pagination
                        align="right"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                    ></b-pagination>
                </div>
            </div>
            <div class="bg-white w-100" v-else-if="rss_list && rss_list.length == 0">
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                    <h4>No items created</h4>
                </div>
            </div>
            <div class="w-100 bg-white" v-else>
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                    <h4>Fetching information...</h4>
                </div>
            </div>
            <div class="profile-delete-modal">
                <DeleteRSSModal
                    v-if="showDeleteAlert"
                    :open="showDeleteAlert"
                    :indexTodelete="indexTodelete"
                    @cancel="showDeleteAlert = false"
                    @handleDelete="handleDelete"
                >
                </DeleteRSSModal>
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import { sendDeleteRssBuildNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import { rssFeeds } from '../../util/apiRequests';
    import DeleteRSSModal from './delete_rss_modal';
    import Loading from '@/components/general/loading/loading.vue';
    import dayjs from '@/util/dayjs';
    export default {
        name: 'RSSList',
        components: {
            DeleteRSSModal,
            Loading,
        },
        data() {
            return {
                rss_list: null,
                showDeleteAlert: false,
                indexTodelete: null,
                fields: [
                    { key: 's_no', label: 'S NO.', thStyle: { 'padding-left': '0 !important' }, class: 'table-col' },
                    { key: 'company_name', label: 'Company Name', class: 'table-col' },
                    { key: 'feedtitle', label: 'Title', class: 'table-col' },
                    { key: 'created_at', label: 'Created Date & Time', class: 'table-col' },
                    { key: 'modified_at', label: 'Modified Date & Time', class: 'table-col' },
                    { key: 'action', label: 'Action', class: 'action-sticky', stickyColumn: true, isRowHeader: true },
                ],

                // b-table properties
                storeKey: 'rssFeeds',
                perPageOptions: [5, 10, 20, 50, 100],
                currentPage: 1,
                perPage: 10,
                totalxRows: null,
                loading: true,
                companyName: null,
            };
        },
        computed: {
            rows() {
                return this.totalRows;
            },
        },
        methods: {
            formattedDate(date) {
                return date ? dayjs(date).format('MM/DD/YYYY hh:mmA') : '';
            },
            changeCountPerPage({ target: { value } }) {
                this.perPage = Number(value);
            },
            savedPagination() {
                const previousPagination = tryGetFilter(this.$store, this.storeKey);
                this.currentPage = previousPagination.currentPage ?? 1;
                this.perPage = previousPagination.perPage ?? 10;
            },
            showDeleteAlertPopUp(id) {
                this.showDeleteAlert = true;
                this.indexTodelete = id;
            },
            async get_company() {
                let companyId = this.$store.state.user.company_id;
                const res = await this.$api.get(`/company/getCompany/${companyId}`);
                this.companyName = res.data.info[0].company_name;
            },
            async getRssList() {
                try {
                    let list = await this.$api.get(rssFeeds.getRssList());
                    let data = list.data.info;
                    this.rss_list = data;
                    this.totalRows = data.length;
                } catch (err) {
                    this.$toasted.error('Failed to fetched Rss List');
                } finally {
                    this.loading = false;
                }
            },
            async handleDelete() {
                try {
                    await this.sendDeleteRssBuildNotification();
                    await this.$api.delete(rssFeeds.deleteRssFeed(this.indexTodelete));
                    this.showDeleteAlert = false;
                    this.$toasted.success('Item deleted successfully');
                    await this.getRssList();
                } catch (e) {
                    this.$toasted.error('Failed to delete item');
                }
            },
            async sendDeleteRssBuildNotification() {
                try {
                    let endpoint = rssFeeds.getListofUser(this.indexTodelete);
                    let res = await this.$api.get(endpoint);
                    if (res?.data?.info?.length) {
                        await sendDeleteRssBuildNotification(res.data.info, this.indexTodelete);
                    }
                } catch (e) {
                    this.$toasted.error('Failed to send notifications to members');
                }
            },
        },
        async created() {
            this.savedPagination();
            await this.get_company();
            await this.getRssList();
            this.loading = false;
        },
        watch: {
            currentPage: function (val) {
                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        perPage: Number(this.perPage),
                        currentPage: Number(val),
                    },
                });
            },
            perPage: function (val) {
                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        currentPage: Number(this.currentPage),
                        perPage: Number(val),
                    },
                });
            },
        },
    };
</script>

<style scoped>
    .bg-color {
        background-color: rgba(255, 255, 255, 0.9);
    }
    .text-button a,
    .text-button a:hover,
    .text-button:hover {
        background-color: #5057c3;
        color: white;
        padding: 10px;
        border-radius: 10px;
    }
</style>

<style>
    .rss-list-table table tbody tr > td:first-child {
        padding-left: 2.5em !important;
    }

    .b-table-sticky-column:last-child {
        right: 0;
        min-width: 100px;
    }

    .action-sticky {
        min-width: 150px;
        box-shadow: -10px 0px 10px 1px #aaaaaa;
    }

    .table-col {
        max-width: 150px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
</style>
